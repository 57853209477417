@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.listingContainer {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid var(--colorGrey100);
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 5px 15px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  /* composes: textSmall from global; */
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorBlack);
  font-size: 14px;
  padding-top: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.data {
  font-size: 12px;
  color: var(--colorBlack);
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-top: 15px;

  a:hover {
    text-decoration: none;
  }
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.brandAndModel {
  font-size: 18px;
  color: var(--marketplaceColor);
}

.button {
  min-height: 30px;
  padding: 0 15px;
  font-size: 14px;
}

.editButton {
  composes: button;
  white-space: nowrap;
  color: var(--colorBlack);

  &:hover {
    color: var(--colorWhite);
  }
}

.buyButton {
  composes: button;
  color: var(--colorWhite);

  &:hover {
    color: var(--colorBlack);
  }
}